import React from "react"
import loadable from '@loadable/component'
import pMinDelay from 'p-min-delay'

import HomeContainer from "../containers/HomeContainer"
import HeadData from "../data/HeadData"
import SkewedHeaderBg from "../headers/SkewedHeaderBg"
import FullHeaderColorBg from '../headers/FullHeaderColorBg'
import HeaderNav from '../navigation/HeaderNav'
import HomeHeroText from "../headers/HomeHeroText"
import StepsSection from "../sections/StepsSection"
import VideoSection from "../sections/VideoSection"
import TestimonialsSection from "../sections/TestimonialsSection"
import WhySection from "../sections/WhySection"
import FeaturesSection from "../sections/FeaturesSection"
import TeamSection from "../sections/TeamSection"
import IntegrationsSection from "../sections/IntegrationsSection"
import CTASection from "../sections/CTASection"

const CustomersLogoCarousel = loadable(() => pMinDelay(import("../sections/CustomersLogoCarousel"), 2000))
const HomeBlogSection = loadable(() => pMinDelay(import("../sections/HomeBlogSection"), 3000))

export default () => (
  <HomeContainer>
    <HeadData isHome />

    <SkewedHeaderBg />

    <FullHeaderColorBg>
      <HeaderNav isHome />
      <HomeHeroText />
    </FullHeaderColorBg>

    <StepsSection />

    <VideoSection />

    <TestimonialsSection />

    <CustomersLogoCarousel />

    <WhySection />

    <FeaturesSection />

    <TeamSection />

    <IntegrationsSection />

    <HomeBlogSection />

    <CTASection />
  </HomeContainer>
)
