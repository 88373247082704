import React from "react"
import { useStaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

const StyledDiv = styled.div`
  z-index: 50;

  .text-huge {
    font-size: 45px;
  }
`

const HomeHeroText = () => {
  const data = useStaticQuery(graphql`
    query HomeHeroTextQuery {
      imageHero: file(relativePath: { eq: "hero/backend_mock.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      imageHeroMobile: file(relativePath: { eq: "hero/backend_mock.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      imageAppstore: file(relativePath: { eq: "sections/app-store.png" }) {
        childImageSharp {
          fluid(maxWidth: 210) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      imageAppstoreMobile: file(relativePath: { eq: "sections/app-store.png" }) {
        childImageSharp {
          fluid(maxWidth: 170) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      imageGoogleplay: file(relativePath: { eq: "sections/google-play.png" }) {
        childImageSharp {
          fluid(maxWidth: 210) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      imageGoogleplayMobile: file(relativePath: { eq: "sections/google-play.png" }) {
        childImageSharp {
          fluid(maxWidth: 170) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  const imageHero = data.imageHero.childImageSharp.fluid
  const imageHeroMobile = data.imageHeroMobile.childImageSharp.fluid
  const imageAppstore = data.imageAppstore.childImageSharp.fluid
  const imageGoogleplay = data.imageGoogleplay.childImageSharp.fluid
  const imageAppstoreMobile = data.imageAppstoreMobile.childImageSharp.fluid
  const imageGoogleplayMobile = data.imageGoogleplayMobile.childImageSharp.fluid

  return (
    <StyledDiv className='sm:w-full md:w-full lg:w-10/12 xl:w-8/12 flex sm:flex-col md:flex-col items-center mt-20 xl:mt-24 mx-auto sm:p-10 md:p-10'>
      <div className='sm:w-full md:w-full w-5/12'>
        <h2 className='font-display text-huge text-grolens-green-900 font-extrabold leading-tight mb-6'>
          Make your next Harvest your Best Harvest
        </h2>

        <h1 className='sm:w-full md:w-full w-3/4 font-body font-medium text-xl sm:text-2xl text-grolens-green-800 mb-6 sm:mb-8'>
          GroLens is the leading all-in-one seed to sale software for commercial growers.
        </h1>

        <Link to='/request-demo'>
          <button className="bg-grolens-green-300 hover:bg-grolens-green-600 text-white text-md sm:text-lg font-medium uppercase py-4 px-16 mb-6 sm:mb-10 md:mb-10">
            Get Price
          </button>
        </Link>

        <div className='sm:w-full w-6/12 flex items-center sm:mb-12 md:mb-12'>
          <a href='https://apps.apple.com/us/app/grolens/id1455680272' className='w-1/2 mr-3'>
            <Img fluid={imageAppstore} alt='' className='w-full sm:hidden' />
            <Img fluid={imageAppstoreMobile} alt='' className='w-full md:hidden lg:hidden xl:hidden' />
          </a>

          <a href='https://play.google.com/store/apps/details?id=com.hiigrade.hiigrade&hl=en_US' className='w-1/2'>
            <Img fluid={imageGoogleplay} alt='' className='w-full sm:hidden' />
            <Img fluid={imageGoogleplayMobile} alt='' className='w-full md:hidden lg:hidden xl:hidden' />
          </a>
        </div>
      </div>

      <div className='sm:w-full md:w-full w-7/12 ml-4 sm:ml-0 md:ml-0'>
        <Img fluid={imageHero} alt='GroLens backend preview image' className='sm:hidden w-full' />
        <Img fluid={imageHeroMobile} alt='GroLens backend preview image' className='md:hidden lg:hidden xl:hidden w-full' />
      </div>
    </StyledDiv>
  )
}

export default HomeHeroText