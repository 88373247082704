import React from 'react'
import styled from 'styled-components'

const StyledDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin-top: -145px;
  width: 100%;
  height: 600px;
  background: #e5f1ee;
  z-index: 10;
  -webkit-transform: skewY(-8deg);
  -moz-transform: skewY(-8deg);
  -ms-transform: skewY(-8deg);
  -o-transform: skewY(-8deg);
  transform: skewY(-8deg);

  @media (max-width:767px) {
    height: 860px;
  }
`

const SkewedHeaderBg = () => (
  <StyledDiv></StyledDiv>
)

export default SkewedHeaderBg