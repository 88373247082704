import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

const StyledDiv = styled.div`
  background: #f3fbf9;
`

const Container = 'flex flex-col items-center sm:w-full w-1/3 text-center p-4 sm:p-0'

const iconImage = 'w-32 sm:w-12 mb-4 sm:hidden'
const iconImageMobile = 'w-32 sm:w-12 mb-4 md:hidden lg:hidden xl:hidden'

const title = 'font-display text-grolens-green-900 text-2xl sm:text-sm font-extrabold uppercase tracking-widest'

const StepsSection = () => {
  const data = useStaticQuery(graphql`
    query StepsSectionQuery {
      imageGrow: file(relativePath: { eq: "sections/grow_icon.png" }) {
        childImageSharp {
          fluid(maxWidth: 130) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      imageGrowMobile: file(relativePath: { eq: "sections/grow_icon.png" }) {
        childImageSharp {
          fluid(maxWidth: 60) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      imageHarvest: file(relativePath: { eq: "sections/harvest_icon.png" }) {
        childImageSharp {
          fluid(maxWidth: 130) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      imageHarvestMobile: file(relativePath: { eq: "sections/harvest_icon.png" }) {
        childImageSharp {
          fluid(maxWidth: 60) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      imageSell: file(relativePath: { eq: "sections/sell_icon.png" }) {
        childImageSharp {
          fluid(maxWidth: 130) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      imageSellMobile: file(relativePath: { eq: "sections/sell_icon.png" }) {
        childImageSharp {
          fluid(maxWidth: 60) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      imageLine: file(relativePath: { eq: "sections/timeline_icon.png" }) {
        childImageSharp {
          fluid(maxWidth: 868) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  const imageGrow = data.imageGrow.childImageSharp.fluid
  const imageHarvest = data.imageHarvest.childImageSharp.fluid
  const imageSell = data.imageSell.childImageSharp.fluid
  const imageGrowMobile = data.imageGrowMobile.childImageSharp.fluid
  const imageHarvestMobile = data.imageHarvestMobile.childImageSharp.fluid
  const imageSellMobile = data.imageSellMobile.childImageSharp.fluid
  const imageLine = data.imageLine.childImageSharp.fluid

  return (
    <StyledDiv className='bg-blue-900 px-24 pb-8 sm:px-4 md:px-4 md:pb-4 sm:pt-6 xl:pt-6'>
      <div className='flex items-start sm:w-full md:w-full lg:w-11/12 xl:w-9/12 mx-auto mb-6'>
        <div className={Container}>
          <Img fluid={imageGrow} alt='Graphic icon of growing' className={iconImage} />
          <Img fluid={imageGrowMobile} alt='Graphic icon of growing' className={iconImageMobile} />
          <h2 className={title}>Grow</h2>
        </div>

        <div className={Container}>
          <Img fluid={imageHarvest} alt='Graphic icon of harvesting' className={iconImage} />
          <Img fluid={imageHarvestMobile} alt='Graphic icon of growing' className={iconImageMobile} />
          <h2 className={title}>Harvest</h2>
        </div>

        <div className={Container}>
          <Img fluid={imageSell} alt='Graphic icon of selling' className={iconImage} />
          <Img fluid={imageSellMobile} alt='Graphic icon of growing' className={iconImageMobile} />
          <h2 className={title}>Sell</h2>
        </div>
      </div>

      <div className='flex sm:w-9/12 md:w-8/12 lg:w-6/12 xl:w-5/12 mx-auto'>
      <Img fluid={imageLine} alt='Illustration of a green line' className='w-full' />
      </div>
    </StyledDiv>
  )
}

export default StepsSection