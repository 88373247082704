import React from 'react'
import styled from 'styled-components'
import YoutubeModal from '../modals/YoutubeModal'

const StyledDiv = styled.div`
  background: #f3fbf9;

  .vid-height {
    height: 500px;

    @media (max-width:767px) {
      height: 200px;
    }
  }
`

const VideoSection = () => (
  <StyledDiv className='pt-6 pb-16 sm:px-4 md:pb-4 sm:pb-4'>
    <div className='sm:w-full md:w-11/12 lg:w-8/12 xl:w-6/12 flex items-center justify-center mx-auto vid-height'>
      <div className='w-full sm:hidden'>
        <YoutubeModal />
      </div>

      <div className='w-full md:hidden lg:hidden xl:hidden'>
        <YoutubeModal isMobile />
      </div>
    </div>
  </StyledDiv>
)

export default VideoSection