import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'
import TestiSection from './TestiSection'

const StyledBackgroundImage = styled(BackgroundImage)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-position: 0% 30%;
  width: 100%;
  min-height: 400px;
`

const TestimonialsSection = () => {
  const data = useStaticQuery(graphql`
    query TestimonialsSection {
      imageBg: file(relativePath: { eq: "sections/testi-bg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  const imageBg = data.imageBg.childImageSharp.fluid

  return (
    <StyledBackgroundImage
      id='testimonials'
      className='z-20'
      fluid={imageBg}
      backgroundColor={`#1a3840`}
    >
      <TestiSection />
    </StyledBackgroundImage>
  )
}

export default TestimonialsSection