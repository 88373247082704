import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

const StyledDiv = styled.div`
  background: #F8FCFB;
`

const IntegrationsSection = () => {
  const data = useStaticQuery(graphql`
    query IntegrationsSection {
      imageOne: file(relativePath: { eq: "logo/metrc_logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 127) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageTwo: file(relativePath: { eq: "logo/cca_logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 275) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageThree: file(relativePath: { eq: "logo/omma_logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 512) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageFour: file(relativePath: { eq: "logo/growlink_logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageFive: file(relativePath: { eq: "logo/ohaus.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  const imageOne = data.imageOne.childImageSharp.fluid
  const imageTwo = data.imageTwo.childImageSharp.fluid
  const imageThree = data.imageThree.childImageSharp.fluid
  const imageFour = data.imageFour.childImageSharp.fluid
  const imageFive = data.imageFive.childImageSharp.fluid

  return (
    <StyledDiv className='p-4 pt-16 pb-10'>

      <h2 className='font-display text-3xl text-center text-grolens-green-600 font-extrabold mb-12'>Integrations</h2>

      <h3 className='font-display text-xl sm:text-lg text-center text-grolens-green-800 font-extrabold uppercase mb-2'>For Compliance</h3>

      <div className='sm:w-full lg:w-6/12 xl:w-4/12 flex items-center mx-auto mb-16'>
        <div className='sm:w-full w-1/3'>
          <Img fluid={imageOne} alt='logo' className='w-32 sm:w-16 mx-auto' />
        </div>

        <div className='sm:w-full w-1/3'>
          <Img fluid={imageTwo} alt='logo' className='w-32 sm:w-16 mx-auto' />
        </div>

        <div className='sm:w-full w-1/3'>
          <Img fluid={imageThree} alt='logo' className='w-24 sm:w-16 mx-auto' />
        </div>
      </div>

      <h3 className='font-display text-xl sm:text-lg text-center text-grolens-green-800 font-extrabold uppercase mb-4'>For Grow</h3>

      <div className='sm:w-full lg:w-6/12 xl:w-6/12 flex sm:flex-col items-start justify-center mx-auto'>
        <div className='w-full mx-8 sm:mx-0 sm:px-4 sm:mb-12'>
          <p className='text-center text-sm text-gray-800'>Sensors available to monitor environment humidity, temperature, and light spectrum.</p>
          <Img fluid={imageFour} alt='logo' className='mx-auto w-64 mt-2 sm:w-40' />
        </div>

        <div className='w-full mx-8 sm:px-4 sm:mx-0'>
          <p className='text-center text-sm text-gray-800'>Scale connects to bluetooth for an integrated experience when weighing plants and finished inventory.</p>
          <Img fluid={imageFive} alt='logo' className='mx-auto w-20 mt-3 sm:w-16' />
        </div>
      </div>
    </StyledDiv>
  )
}

export default IntegrationsSection