import React from 'react'

const quoteStyle = 'font-body text-2xl sm:text-xl text-center text-white mb-4 sm:p-2'

const TestiSection = () => (
  <div className='sm:w-full md:w-full lg:w-10/12 xl:w-8/12 sm:px-10 sm:pt-12 sm:pb-16 md:px-10 md:pt-12 md:pb-16'>
    <h2 className='font-display text-3xl sm:text-2xl text-center text-grolens-green-600 font-extrabold mb-8'>Testimonials</h2>

    <div className='grid grid-cols-3 sm:grid-cols-1 gap-12'>
      <div>
        <p className={quoteStyle}>"Once I saw GroLens everything about it made it <b>super simple</b> and <b>easy to operate</b>."</p>
        <p className='font-body text-xs text-center text-grolens-green-500 font-extrabold uppercase'>Dillion D.,<br className='md:hidden lg:hidden xl:hidden' /> THC Design</p>
      </div>

      <div>
        <p className={quoteStyle}>"Way more <b>intuitive</b> than MJ Freeway & any other platform."</p>
        <p className='font-body text-xs text-center text-grolens-green-500 font-extrabold uppercase'>Kris Harris,<br className='md:hidden lg:hidden xl:hidden' /> Lithouse Farms</p>
      </div>

      <div>
        <p className={quoteStyle}>"You can tell <b>it’s made for the grower</b>. It makes track & trace no longer scary."</p>
        <p className='font-body text-xs text-center text-grolens-green-500 font-extrabold uppercase'>Steve Matson,<br className='md:hidden lg:hidden xl:hidden' /> Highness Gardens</p>
      </div>
    </div>
  </div>
)

export default TestiSection