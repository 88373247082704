import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Popup from 'reactjs-popup'
import Img from 'gatsby-image'
import ReactPlayer from 'react-player/youtube'

const StyledCloseIcon = styled.a`
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
  z-index: 1;
`

const StyledDiv = styled.div`
  position: absolute;
  width: 80px;
  height: 56px;
  left: 50%;
  margin-left: -40px;
  top: 50%;
  margin-top: -23px;
`

const StyledVidModal = styled.div`
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */

  .react-player iframe, .react-player__preview {
    position: absolute;
    top: 0;
    left: 0;
  }
`

const YoutubeModal = ({ youtubeID, imageData, imageAlt, isMobile }) => {
  const data = useStaticQuery(graphql`
    query YoutubeModalQuery {
      imageDefault: file(relativePath: { eq: "sections/home-video-placeholder.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1274) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageButton: file(relativePath: { eq: "sections/vid-play-button.png" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const imageDataDefault = data.imageDefault.childImageSharp.fluid
  const imageButton = data.imageButton.childImageSharp.fluid

  return (
    <Popup
      trigger={
        <div className='relative cursor-pointer'>
          <StyledDiv className='flex items-center justify-center z-10'>
            <Img fluid={imageButton} alt='play video button' className='w-full transform sm:scale-75 hover:scale-125 cursor-pointer' />
          </StyledDiv>
          <Img fluid={imageData ? imageData : imageDataDefault} className='shadow-xl' alt={imageAlt ? imageAlt : 'Youtube video image placeholder'} />
        </div>
      }
      modal
      lockScroll
      overlayStyle={{ background: "rgba(0,0,0,0.9)", zIndex: '9999' }}
      contentStyle={{ width: isMobile ? '95%' : '80%', background: '#52B2AD', borderColor: '#52B2AD'  }}
    >
      {close => (
        <StyledVidModal className="modal">
          <StyledCloseIcon onClick={close}>&times;</StyledCloseIcon>

          <ReactPlayer
            className='react-player'
            url={
              youtubeID
              ?
              `https://www.youtube.com/watch?v=${youtubeID}`
              :
              'https://www.youtube.com/watch?v=EVz9fl72CvY'
            }
            config={{
              playerVars: {
                autoplay: 1,
              },
            }}
            controls
            width='100%'
            height='100%'
          />
        </StyledVidModal>
      )}
    </Popup>
  )
}

export default YoutubeModal

YoutubeModal.propTypes = {
  youtubeID: PropTypes.string,
  imageData: PropTypes.object,
  imageAlt: PropTypes.string,
  isMobile: PropTypes.bool,
}