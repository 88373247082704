import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

const StyledDiv = styled.div`
  background: #E7F1EF;
`

const StyledSkewTop = styled.div`
  height: 30px;
  margin-top: -24px;
  -webkit-transform: skewY(0.5deg);
  -moz-transform: skewY(0.5deg);
  -ms-transform: skewY(0.5deg);
  -o-transform: skewY(0.5deg);
  transform: skewY(0.5deg);

  @media (max-width:767px) {
    margin-top: -27px;
  }
`

const WhySection = () => {
  const data = useStaticQuery(graphql`
    query WhySectionQuery {
      imageOne: file(relativePath: { eq: "sections/grolensvin.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  const imageOne = data.imageOne.childImageSharp.fluid

  return (
    <StyledDiv id='why-grolens' className='relative p-4 py-24 sm:px-10'>
      <StyledSkewTop className='absolute top-0 left-0 right-0 w-full bg-grolens-green-500'></StyledSkewTop>

      <div className='sm:w-full md:w-11/12 lg:w-10/12 xl:w-8/12 flex sm:flex-col items-center mx-auto'>
        <div className='sm:w-full w-1/2'>
          <h2 className='font-display text-grolens-green-900 font-extrabold text-4xl mb-2'>Why Grolens?</h2>

          <p className='font-body text-2xl sm:text-xl text-grolens-green-800'>GroLens was founded by California Growers that understand the many complexities of running a successful operation. We provide growers with a simple tool to help you stay ahead of compliance reporting, manage your staff, and ensure healthy plants all in one place. Whether you are outdoor, mixed light, or an indoor operation, GroLens understands your business and has the tools to help. </p>
        </div>

        <div className='sm:w-full w-1/2 sm:mt-12'>
          <Img fluid={imageOne} alt='' />
        </div>
      </div>
    </StyledDiv>
  )
}

export default WhySection