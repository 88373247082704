import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'

const StyledDiv = styled.div`
  background: #E7F1EF;
`

const pStyle = 'font-body text-grolens-green-800 text-lg sm:text-xl mb-3'
const listStyle = 'list-disc my-6'
const listItemStyle = 'text-grolens-green-800 font-medium ml-6 mb-2'

const TeamSection = () => {
  const data = useStaticQuery(graphql`
    query TeamSectionQuery {
      imageTeam: file(relativePath: { eq: "sections/team-pic.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2200) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  const imageTeam = data.imageTeam.childImageSharp.fluid

  return (
    <StyledDiv id='team' className='p-4 pt-24 pb-16 sm:px-10 md:px-10'>

      <h2 className='font-display text-3xl text-center text-grolens-green-600 font-extrabold mb-12'>Our Team</h2>

      <div className='sm:w-full md:w-full lg:w-9/12 xl:w-7/12 flex sm:flex-col-reverse md:flex-col-reverse items-center mx-auto mb-16'>
        <div className='sm:w-full md:w-full w-1/2 mr-6 sm:mr-0'>
          <p className={pStyle}>GroLens' mission is to advance the integrity of the cannabis industry with technology. We are fully prepared to achieve this mission by providing excellent value to our partners with a diverse team from all walks of life.</p>

          <p className={pStyle}>Our team has a robust background including:</p>

          <ul className={listStyle}>
            <li className={listItemStyle}>8+ years of commercial cultivation experience growing under prop 215 and prop 64 in the world's largest Cannabis Market.</li>
            <li className={listItemStyle}>Computer Science Degrees from University of Boulder in the nation's first recreational Cannabis Market</li>
            <li className={listItemStyle}>Experience developing technology for the healthcare industry and building out customer support infrastructure at some of the fastest growing startups.</li>
          </ul>

          <p className={pStyle}>Everyone on our team is passionate about the industry and is dedicated to providing value to every grower on the platform.</p>
        </div>

        <div className='sm:w-full md:w-full w-1/2 ml-6 sm:ml-0 md:ml-0 sm:mb-6 md:mb-6'>
          <Img fluid={imageTeam} alt='' className='w-full md:w-1/2 rounded-full border-4 border-grolens-green-400 md:mx-auto' />
        </div>
      </div>

      <div className='sm:w-full md:w-full w-9/12 mx-auto flex justify-center'>
        <Link to='/request-demo'>
          <button className="bg-grolens-green-300 hover:bg-grolens-green-600 text-white text-md font-medium uppercase py-4 px-12 mt-6 sm:mt-0">
            Request Demo
          </button>
        </Link>
      </div>
    </StyledDiv>
  )
}

export default TeamSection