import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 600px;
  background: #f3fbf9;
`

const FullHeaderColorBg = ({ children }) => (
  <StyledDiv>
    {children}
  </StyledDiv>
)

FullHeaderColorBg.propTypes = {
  children: PropTypes.node,
}

export default FullHeaderColorBg