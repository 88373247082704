import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

import CheckMark from '../assets/checkmark.svg'

const container = 'sm:w-10/12 lg:w-9/12 xl:w-7/12 flex sm:flex-col items-center mx-auto mb-16'
const container2 = 'sm:w-10/12 lg:w-9/12 xl:w-7/12 flex sm:flex-col-reverse items-center mx-auto mb-16'
const imageWrapper = 'sm:w-full w-1/2'
const textWrapper = 'sm:w-full w-1/2 flex flex-col ml-12 sm:ml-0'
const title = 'font-display text-grolens-green-900 font-extrabold text-4xl sm:text-3xl sm:text-center leading-tight mb-6'
const list = 'flex sm:flex-col items-start sm:items-center my-4'
const icon = 'w-8 mt-1 mr-6 sm:mr-0 sm:mb-4'
const listItem = 'sm:w-full w-2/3 font-body text-grolens-green-800 text-lg sm:text-center'
const linkButton = "bg-grolens-green-300 hover:bg-grolens-green-600 text-white text-md font-medium uppercase py-4 px-12 mt-6"

const FeaturesSection = () => {
  const data = useStaticQuery(graphql`
    query FeaturesSectionQuery {
      imageComp: file(relativePath: { eq: "sections/compliance.png" }) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      imageCompMobile: file(relativePath: { eq: "sections/compliance.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      imageMax: file(relativePath: { eq: "sections/maximize-harvest.png" }) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      imageMaxMobile: file(relativePath: { eq: "sections/maximize-harvest.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      imageProd: file(relativePath: { eq: "sections/product-market-faster.png" }) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      imageProdMobile: file(relativePath: { eq: "sections/product-market-faster.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  const imageComp = data.imageComp.childImageSharp.fluid
  const imageMax = data.imageMax.childImageSharp.fluid
  const imageProd = data.imageProd.childImageSharp.fluid
  const imageCompMobile = data.imageCompMobile.childImageSharp.fluid
  const imageMaxMobile = data.imageMaxMobile.childImageSharp.fluid
  const imageProdMobile = data.imageProdMobile.childImageSharp.fluid

  return (
    <div id='features' className='bg-grolens-white-200 p-4 py-24 sm:px-10 z-10'>

      <h2 className='font-display text-3xl text-center text-grolens-green-600 font-extrabold mb-12'>Features</h2>

      <div className={container}>
        <div className={imageWrapper}>
          <Img fluid={imageProd} alt='' className='sm:hidden' />
          <Img fluid={imageProdMobile} alt='' className='md:hidden lg:hidden xl:hidden' />
        </div>

        <div className={textWrapper}>
          <h2 className={title}>Get product to market faster</h2>

          <div className={list}>
            <div className={icon}>
              <CheckMark />
            </div>

            <p className={listItem}>Scale integration allows you to record harvest and create sellable inventory in a fraction of the steps.</p>
          </div>

          <div className={list}>
            <div className={icon}>
              <CheckMark />
            </div>

            <p className={listItem}>Inventory management, sales orders, and invoices can be created on any device from the palm of your hand.</p>
          </div>
        </div>
      </div>

      <div className={container2}>
        <div className={textWrapper}>
          <h2 className={title}>Maximize harvest per year</h2>

          <div className={list}>
            <div className={icon}>
              <CheckMark />
            </div>

            <p className={listItem}>Set up production schedules in advance to maximize harvest per year and ensure you stay on track.</p>
          </div>

          <div className={list}>
            <div className={icon}>
              <CheckMark />
            </div>

            <p className={listItem}>Team members are notified of weekly tasks directly on their mobile device and can mark when task are complete, improving accountability and consistency.</p>
          </div>
        </div>

        <div className={imageWrapper}>
          <Img fluid={imageMax} alt='' className='sm:hidden' />
          <Img fluid={imageMaxMobile} alt='' className='md:hidden lg:hidden xl:hidden' />
        </div>
      </div>

      <div className={container}>
        <div className={imageWrapper}>
          <Img fluid={imageComp} alt='' className='sm:hidden' />
          <Img fluid={imageCompMobile} alt='' className='md:hidden lg:hidden xl:hidden' />
        </div>

        <div className={textWrapper}>
          <h2 className={title}>Stay ahead of compliance reporting and SOP's</h2>

          <div className={list}>
            <div className={icon}>
              <CheckMark />
            </div>

            <p className={listItem}>Scan plant barcodes with mobile to report new plants or updates existing plants with ease.</p>
          </div>

          <div className={list}>
            <div className={icon}>
              <CheckMark />
            </div>

            <p className={listItem}>METRC state integration is available across multiple states to ensure compliance reporting is done correctly and in real time.</p>
          </div>

          <div className={list}>
            <div className={icon}>
              <CheckMark />
            </div>

            <p className={listItem}>One Click report generation allows you to quickly get out reports on inventory, sales, or plant treatment.</p>
          </div>
        </div>
      </div>

      <div className='sm:w-full w-9/12 mx-auto flex justify-center'>
        <Link to='/request-demo'>
          <button className={linkButton}>
            Request Demo
          </button>
        </Link>
      </div>
    </div>
  )
}

export default FeaturesSection